import React from "react"
import {  navigate } from "gatsby"
import { inject, observer } from "mobx-react"
import ReactMarkdown from "react-markdown/with-html"

import colors from "./colors"


const Job = inject('store')(observer((props) => {
  const styles = props.store.device === 'desktop' ? desktopStyles : mobileStyles
  const { data, lang } = props
  return (
    <div style={styles.workshopContainer}>
      <div style={styles.imageContainer} onClick={() => navigate(data.url, { state: { data: data } })}>
        <img src={data.previewVisual} alt="workshop preview visual" style={styles.image} />
      </div>
      <div className={"InfoContainer"} style={styles.infoContainer}>
        <div className={"TextContainer"} style={styles.textContainer}>
          <div className={"header"} style={styles.header}>
            {data.jobTitle}
          </div>
          <div style={styles.description}>
          <ReactMarkdown
                  source={data.previewDescription}
                  escapeHtml={false}
                />
          </div>
        </div>


      </div>

      <div
        onClick={() => navigate(data.url, { state: { data: data } })}
        state={data}
        style={styles.button}
      >
        <p style={styles.buttonText}>
          {lang === "en" ? "Learn More" : "Erfahre mehr"}
        </p>
      </div>
    </div>
  )
}))

export default Job

const desktopStyles = {
  tagContainer: {
    flex: 0,
    height: "50px",
    margin: "5px 0px",
    alignSelf: "flex-end",
    width: "100%",
    backgroundColor: colors.lightgrey,
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  tag: { margin: 0, color: colors.textDarkgrey, fontSize: "1rem" },
  workshopContainer: {
    margin: "3% 5% 3% 0%",
    width: 320,
    minHeight: 700,
    backgroundColor: colors.white,
    display: "flex",
    flexDirection: "column",
  },
  imageContainer: {
    cursor: "pointer",
    height:'150px', width:'100%'
  },
  image: { 
      width: "100%", 
      height: "100%", 
      objectFit: "cover" 
    },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: "5%",
  },
  textContainer: { flex: 1, color: colors.textDarkgrey },
  header: { fontWeight: "bold", fontSize: "25px", padding: "20px 0px" },
  duration: { fontSize: "1rem" },
  description: { fontSize: "1.125rem" },
  button: {
    minHeight: "55px",
    alignSelf: "flex-end",
    width: "100%",
    backgroundColor: colors.gold,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: 'pointer',
  },
  buttonText: {
    margin: 0,
    color: colors.white,
    fontSize: 18,
    fontWeight: "bold",
  },
}
const mobileStyles = {
  tagContainer: {
    flex: 0,
    height: "50px",
    margin: "5px 0px",
    alignSelf: "flex-end",
    width: "100%",
    backgroundColor: colors.lightgrey,
    display: "flex",
    alignItems: "center",
    padding: "10px",
  },
  tag: { margin: 0, color: colors.textDarkgrey, fontSize: "1rem" },
  workshopContainer: {
    margin: "3%",
    width: 340,
    minHeight: 700,
    backgroundColor: colors.white,
    display: "flex",
    flexDirection: "column",
  },
  imageContainer: {
    height:700*0.2, width:'100%'
  },
  image: { width: "100%", height: "100%", objectFit: "cover" },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    margin: "5%",
  },
  textContainer: { flex: 1, color: colors.textDarkgrey },
  header: { fontWeight: "bold", fontSize: "25px", padding: "20px 0px" },
  duration: { fontSize: "1rem" },
  description: { fontSize: "1.125rem" },
  button: {
    minHeight: "55px",
    alignSelf: "flex-end",
    width: "100%",
    backgroundColor: colors.gold,
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    justifyContent: "center",
  },
  buttonText: {
    margin: 0,
    color: colors.white,
    fontSize: 18,
    fontWeight: "bold",
  },
}

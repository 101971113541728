import React from "react"
import Helmet from "react-helmet"
import { inject, observer } from "mobx-react"
import { Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"


import Layout from "../components/layout"
import colors from "../components/colors"
import Job from "../components/job"
import ContactBanner from "../components/ContactBanner"
import Newsletter from "../components/Newsletter"
import Medium from "../components/medium"

import de_careers from "../../content/de_careers.json"
import en_careers from "../../content/en_careers.json"



const Careers = props => {
  
  en_careers.job.map(
    item =>
      (item.url = `/careers/hiring-${item.jobTitle
        .replace(/\s+|\?|\#/g, '-')
        .toLowerCase()}/`)
  )
  de_careers.job.map(
    (item, i) =>
      (item.url = `/careers/hiring-${en_careers.job[i].jobTitle
        .replace(/\s+|\?|\#/g, '-')
        .toLowerCase()}/`)
  )
  
  const { language, device } = props.store
  const styles = device === 'desktop'? desktopStyles : mobileStyles
  const jobs = 
    language === "en" ? en_careers.job : de_careers.job
  const careers = language === "en" ? en_careers : de_careers

  return (
    <Layout>
    <Helmet>
          <title>{'LT | Careers'}</title>
        </Helmet>
    <div style={{position:'relative'}}>
      <div style={styles.container}>
        <img
        style={{width:'100%', maxHeight:'150px', objectFit:'cover'}}
        src={careers.careersVisual}
        alt="Fans cheering"
      />
        <div style={styles.contentContainer}>
          <div style={styles.upperSection}>
            <div style={styles.breadCrumbContainer}>
              <Link to={"/"} style={styles.link}>
                Home
              </Link>
              {" / "}
              Careers
            </div>
            <div style={{paddingBottom:'50px', marginBottom: '1.45rem'}}>
              <h1 style={{color:colors.black}}>
                Careers
              </h1>
              <ReactMarkdown
                  source={careers.jobDescription}
                  escapeHtml={false}
                />
            </div>
          </div>
          <div style={styles.lowerSection}>
            <div style={styles.headerSection}>
              <h2 style={styles.header}>{language==='en'? 'Open Positions':'Aktuelle Rollenprofile'} </h2>
              <p style={styles.description}>
                {}
              </p>
            </div>
            <div style={styles.workshopSection}>
              {jobs.map((job, i) => (
                <Job key={i} data={job} lang={language} />
              ))}
            </div>
          </div>
        </div>

        <div style={styles.mediumSection}>
          <div style={styles.mediumContainer}>
            <div>
              <h2 style={styles.header}>
                {props.store.language === "en"
                  ? "Current Thinking"
                  : "Aktuelle Artikel"}
              </h2>
              <Medium />
            </div>
          </div>
        </div>
      </div>

      <ContactBanner backgroundColor={colors.white} />
      <Newsletter />
      </div>
    </Layout>
  )
}

export default inject("store")(observer(Careers))

const desktopStyles = {
  container: { backgroundColor: colors.greyBackground },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    padding: "50px 0px",
  },
  breadCrumbContainer: { paddingBottom: "50px" },
  upperSection: { padding: "50px 0px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  headerSection: { display: "flex",marginBottom: '1.45rem', justifyContent: "space-between" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  description: { width: "70%" },
  workshopSection: {
    width: "105%",
    display: "flex",
    flexWrap: "wrap",
    //justifyContent: "flex-start",
    //alignItems: "flex-start",
  },
  lowerSection: { padding: "50px 0px" },
  mediumSection: { backgroundColor: "white" },
  mediumContainer: {
    maxWidth: "80%",
    margin: "auto",
    padding: "50px 0px",
  },
}

const mobileStyles = {
  container: { backgroundColor: colors.greyBackground },
  contentContainer: {
    maxWidth: "80%",
    margin: "auto",
    color: colors.textDarkgrey,
    //padding: "50px 0px",
  },
  breadCrumbContainer: { paddingBottom: "50px" },
  upperSection: { padding: "50px 0px" },
  link: { color: colors.textDarkgrey, textDecoration: "none" },
  headerSection: { display: "flex",flexDirection:'column', marginBottom: '1.45rem', justifyContent: "space-between" },
  header: {
    fontSize: "1.5625rem",
    color: colors.black,
  },
  description: { width: "100%" },
  workshopSection: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  lowerSection: { padding: "50px 0px" },
  mediumSection: { backgroundColor: "white" },
  mediumContainer: {
    maxWidth: "80%",
    margin: "auto",
    padding: "50px 0px",
  },
}
